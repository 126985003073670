export const hScroller = () => {
  const callback = (mutationList, observer) => {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        addedNodes = mutation.addedNodes;
        if (addedNodes.length > 0) {
          addedNodes.forEach((node) => {
            console.log("AddedNode", node);
          });
        }

        removedNodes = mutation.removedNodes;
        if (removedNodes.length > 0) {
          removedNodes.forEach((node) => {
            console.log("RemovedNode", node);
          });
        }
      }
    }
  };

  const maxAttempts = 50;
  let attempt = 0;

  const observeTarget = () => {
    const targetNode = document.querySelector(".page-header");
    if (targetNode) {
      const config = { attributes: true, childList: true, subtree: true };
      const observer = new MutationObserver(callback);
      observer.observe(targetNode, config);
    } else if (attempt < maxAttempts) {
      attempt++;
      setTimeout(observeTarget, 100);
    } else {
      console.warn("page-header not found");
    }
  };

  observeTarget();

  const checkScrollPosition = (scrollEl, element) => {
    const scrollDistance = scrollEl.scrollLeft;
    const maxScrollDistance = scrollEl.scrollWidth - scrollEl.clientWidth;

    if (scrollDistance <= 0) {
      element.classList.add("scroll-pos-start");
      element.classList.remove("scroll-pos-end");
      element.querySelector(".btn-scroll--left").disabled = true;
      element.querySelector(".btn-scroll--right").disabled = false;
    } else if (scrollDistance >= maxScrollDistance) {
      element.classList.add("scroll-pos-end");
      element.classList.remove("scroll-pos-start");
      element.querySelector(".btn-scroll--right").disabled = true;
      element.querySelector(".btn-scroll--left").disabled = false;
    } else {
      element.classList.remove("scroll-pos-start", "scroll-pos-end");
      element.querySelector(".btn-scroll--left").disabled = false;
      element.querySelector(".btn-scroll--right").disabled = false;
    }
  };
  const hScrollElements = document.querySelectorAll(".h-scroll-container, .hscroll");

  hScrollElements.forEach((element) => {
    const scrollEl = element.querySelector(".h-scroll-wrapper, .hscroll-slider");

    if (scrollEl) {
      // Create scroll buttons dynamically
      const scrollBtnsContainer = document.createElement("div");
      scrollBtnsContainer.className = "hscroll-btns";

      const btnLeft = document.createElement("button");
      btnLeft.className = "btn-scroll btn-scroll--left icon-chevron-left";
      scrollBtnsContainer.appendChild(btnLeft);

      const btnRight = document.createElement("button");
      btnRight.className = "btn-scroll btn-scroll--right icon-chevron-right";
      scrollBtnsContainer.appendChild(btnRight);

      scrollEl.parentElement.appendChild(scrollBtnsContainer);

      checkScrollPosition(scrollEl, element);

      window.addEventListener("resize", () => {
        checkScrollPosition(scrollEl, element);
      });

      const scrollItems = scrollEl.children;
      const itemSize = scrollItems[0].getBoundingClientRect().width;

      const handleScroll = (direction) => {
        const maxScrollDistance = scrollEl.scrollWidth - scrollEl.clientWidth;
        let scrollDistance = scrollEl.scrollLeft; // Get the current scroll position

        // Update scroll position based on direction
        if (direction === "left" && scrollDistance > 0) {
          scrollDistance = Math.max(0, scrollDistance - itemSize);
        } else if (direction === "right" && scrollDistance < maxScrollDistance) {
          scrollDistance = Math.min(maxScrollDistance, scrollDistance + itemSize);
        }

        // Perform the scroll
        scrollEl.scrollTo({ left: scrollDistance, behavior: "smooth" });

        // Check scroll position immediately after scrolling
        checkScrollPosition(scrollEl, element);
      };

      // Attach event listeners to dynamically created buttons
      btnLeft.addEventListener("click", () => handleScroll("left"));
      btnRight.addEventListener("click", () => handleScroll("right"));

      // Listen to scroll events in case of manual scrolling
      scrollEl.addEventListener("scroll", () => {
        checkScrollPosition(scrollEl, element);
      });

      // Initial check to set the correct classes
      checkScrollPosition(scrollEl, element);
    }
  });
};
