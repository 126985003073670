import { breakPointProps } from "./components/breakpoints";

import DOMElementsInfo from "./components/domElements";
import { stickyHeader } from "./components/stickyheader";

import { progressBar } from "./components/progressBar";
import { hScroller } from "./components/hScroll";
import { gallery } from "./components/gallery";
import { topScrollArrow } from "./components/banners";
import { bannerInView } from "./components/bannerinview";
import "./components/binded-content";
import { expandable } from "./components/expandables";

import "../hestemediet/assets/css//hestemediet.purged.css";

document.body.classList.add("loading");

let LMContentLoaded = false;
window.init = function () {
  if (!LMContentLoaded) {
    const observer = new MutationObserver(() => {
      if (!LMContentLoaded) {
        document.dispatchEvent(new Event("LMContentLoaded"));
        LMContentLoaded = true;
        observer.disconnect();
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }
};

document.addEventListener(
  "LMContentLoaded",
  () => {
    document.body.classList.remove("loading");
    stickyHeader();
    breakPointProps();
    gallery();
    hScroller();
    progressBar();
    topScrollArrow();
    expandable();
  },
  { once: true }
);
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", window.init);
} else {
  window.init();
}
