import throttle from "just-throttle";

export const stickyHeader = () => {
  const handleScroll = () => {
    const header = document.querySelector(".page-header");

    if (!header) {
      return;
    }

    const headerRect = header.getBoundingClientRect();

    if (headerRect.top <= 0) {
      document.body.classList.add("has-sticky-header");
    } else {
      document.body.classList.remove("has-sticky-header");
    }
  };

  window.addEventListener("scroll", throttle(handleScroll, 50));

  handleScroll();
};
/*
export const waitForHeaderHeight = () => {
  function waitForHeaderHeightChange(callback) {
    const targetNode = document.documentElement;

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "style") {
          const headerHeight = getComputedStyle(targetNode).getPropertyValue("--header-height").trim();

          if (headerHeight) {
            observer.disconnect();
            callback(headerHeight);
          }
        }
      });
    });

    observer.observe(targetNode, { attributes: true, attributeFilter: ["style"] });
  }

  waitForHeaderHeightChange((headerHeight) => {
    document.body.classList.add("has-header-height");
  });
};

waitForHeaderHeight(); */
