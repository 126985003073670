// kører kun på mindbar, på sitet er det implementeret af danny
export function bannerInView() {
  console.log("bannerInView");
  const scrollBanners = document.querySelectorAll(".lm-banner:has(.banner--interscroll, .banner--topscroll)");

  //create intersection observer to check whether banner is in view
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("js-banner--inview");
      } else {
        entry.target.classList.remove("js-banner--inview");
      }
    });
  }, options);
  scrollBanners.forEach((banner) => {
    observer.observe(banner);
  });
}
if (window.location.host.includes("mindbar") || window.location.host.includes(".test")) {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", bannerInView);
  } else {
    bannerInView();
  }
}
