export const gallery = () => {
  const carousel = document.querySelector("#article-img-carousel .carousel-inner");
  const galleries = document.querySelectorAll(".gallery");

  if (galleries && carousel) {
    let isFirst = true;

    galleries.forEach((gallery, index) => {
      const images = gallery.querySelectorAll("img");

      images.forEach((img) => {
        const itemClassList = isFirst ? "carousel-item active" : "carousel-item";
        isFirst = false;
        let carouselItem = `<div class="${itemClassList}">`;
        const imgSrc = img.src.replace("xsthumb", "max");
        carouselItem += `<img src="${imgSrc}" alt="${img.alt}" class="d-block w-100 ratio ratio-16x9">`;
        const caption = gallery.querySelector("figcaption");

        if (caption) {
          const captionClone = caption.cloneNode(true);
          captionClone.classList.add("carousel-caption");
          carouselItem += captionClone.outerHTML;
        }
        carouselItem += "</div>";

        carousel.innerHTML += carouselItem;

        const link = img.closest("a");

        if (link && link.href) {
          link.setAttribute("data-bs-target", "#article-img-carousel");
          link.setAttribute("data-bs-toggle", "modal");

          link.setAttribute("data-bs-slide-to", index);
          link.setAttribute("role", "button");
          link.setAttribute("href", "#");
        }
      });
    });

    /*   galleries.forEach((el) => {
      el.addEventListener("click", (e) => {
        const targetImgSrc = e.target.getAttribute("data-src") || e.target.src; // Brug data-src hvis tilgængelig, ellers src
        const carouselItems = imgCarousel.querySelectorAll(".carousel-item");
        const modalCarousel = bootstrap.Carousel.getInstance(modalImgCarousel);

        carouselItems.forEach((item, index) => {
          item.classList.remove("active");
          if (item.dataset.img === targetImgSrc) {
            // Match baseret på korrekt billede URL
            item.classList.add("active");
            if (modalCarousel) {
              modalCarousel.to(index);
            }
          }
        });
      });
    }); */
  }
};
