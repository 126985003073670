export function breakPointProps() {
  const mediaBreakpoints = {
    xs: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--media-breakpoint-xs")),
    sm: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--media-breakpoint-sm")),
    md: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--media-breakpoint-md")),
    lg: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--media-breakpoint-lg")),
    xl: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--media-breakpoint-xl")),
    xxl: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--media-breakpoint-xxl")),
  };

  const containerBreakpoints = {
    xs: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--container-breakpoint-xs")),
    sm: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--container-breakpoint-sm")),
    md: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--container-breakpoint-md")),
    lg: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--container-breakpoint-lg")),
    xl: parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--container-breakpoint-xl")),
  };
  const containerBreakpointsJson = JSON.stringify(containerBreakpoints);
  const mediaBreakpointsJson = JSON.stringify(mediaBreakpoints);

  document.documentElement.setAttribute("data-container-breakpoints", containerBreakpointsJson);
  document.documentElement.setAttribute("data-media-breakpoints", mediaBreakpointsJson);

  function getCurrentBreakpoint(windowWidth, mediaBreakpoints) {
    const breakpointNames = Object.keys(breakpointWidths);
    let currentBreakpoint = breakpointNames[0];

    for (let i = 0; i < breakpointNames.length; i++) {
      const breakpointName = breakpointNames[i];
      const breakpointWidth = breakpointWidths[breakpointName];
      if (windowWidth >= breakpointWidth) {
        currentBreakpoint = breakpointName;
      }
    }
    return currentBreakpoint;
  }
}
