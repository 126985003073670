export function topScrollArrow() {
  const topScroll = document.querySelector("#topscroll");
  if (topScroll) {
    topScroll.insertAdjacentHTML("beforeend", '<button class="js-topscroll-arrow"><i class="fa-solid fa-circle-chevron-down"></i></button>');
    topScroll.addEventListener("click", function () {
      document.body.classList.add("has-scrolled");
      const header = document.querySelector(".page-header");
      if (header) {
        header.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    });
  }
}
