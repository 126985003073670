export function expandable() {
  console.log("expandable start");

  const states = {
    elementExpanding: false,
    expandingTimer: null,
  };

  document.body.addEventListener("click", function (event) {
    // Tjek om det klikkede element eller en af dets forældre har klassen "card--factbox-expandable"
    const element = event.target.closest(".card--factbox-expandable");
    if (!element) {
      return; // Hvis det klikkede element ikke er en "card--factbox-expandable", gør ingenting
    }

    console.log("klik på element");

    const elementHeight = element.offsetHeight;
    const clickPosition = event.clientY - element.getBoundingClientRect().top;

    const threshold = 64;

    console.log(clickPosition, elementHeight, threshold);

    if (clickPosition > elementHeight - threshold) {
      console.log("Klik inden for nederste 4rem!");
      if (element.classList.contains("expanded") && !states.elementExpanding) {
        element.style.maxHeight = "";
        element.classList.remove("expanded");
        setExpandingTimer(element);
      }
      if (!element.classList.contains("expanded") && !states.elementExpanding) {
        const expandedHeight = element.scrollHeight;
        element.style.maxHeight = expandedHeight + "px";
        element.classList.add("expanded");
        setExpandingTimer(element);
      }
    }
  });

  function setExpandingTimer(el) {
    if (!states.elementExpanding) {
      el.classList.add("expanding");
      states.elementExpanding = true;
    }

    if (states.expandingTimer) {
      clearTimeout(states.expandingTimer);
    }

    states.expandingTimer = setTimeout(function () {
      states.elementExpanding = false;
      el.classList.remove("expanding");
    }, 500);
  }
}
