import throttle from "just-throttle";

export const progressBar = () => {
  const progressContainer = document.querySelector(".progress");

  if (progressContainer) {
    const article = document.querySelector(".article");
    const progressBar = document.querySelector(".progress-bar");
    console.log("progressBar", progressBar);

    let articleHeight;
    const setArticleHeight = () => {
      articleHeight = article.offsetHeight;
    };

    setArticleHeight();
    window.addEventListener("resize", () => requestAnimationFrame(setArticleHeight));

    const fn = throttle(() => updateProgressBarWidth(), 100);
    window.addEventListener("scroll", fn);

    function updateProgressBarWidth() {
      const rect = article.getBoundingClientRect();
      const scrollY = window.scrollY || window.pageYOffset;
      const articleDistanceTop = Math.floor(rect.top + scrollY);
      const scrollDistance = (window.scrollY || window.pageYOffset) + 150;
      const progress = Math.max(0, Math.min(1, (scrollDistance - articleDistanceTop) / articleHeight));
      const articleProgress = Math.floor(progress * 100);
      progressBar.style.width = articleProgress + "%";
      progressContainer.style.setProperty("--article-progress-bar", articleProgress + "%");
      progressBar.setAttribute("aria-valuenow", articleProgress + "%");
    }
  }
};
