export default class DOMElementsInfo {
  constructor() {
    const instance = DOMElementsInfo.instance;
    if (instance) {
      return instance;
    }
    DOMElementsInfo.instance = this;
    this.elements = {};
  }

  init() {
    this.updateElements();

    window.addEventListener("scroll", () => {
      this.updateElements();
    });

    window.addEventListener("resize", () => {
      this.updateElements();
    });
  }

  updateElements() {
    const root = document.documentElement;
    const elements = {
      body: document.querySelector("body"),
      branding: document.querySelector(".lm-branding"),
      navbar: document.querySelector(".lm-navbar"),
      progressBar: document.querySelector(".article-progress-bar"),
      featured: document.querySelector(".featured-news"),
      topscroll: document.querySelector(".banner--topscroll"),
      header: document.querySelector(".page-header"),
    };

    for (const [key, element] of Object.entries(elements)) {
      if (element) {
        const rect = element.getBoundingClientRect();
        const elementData = {
          element: element,
          width: rect.width,
          height: rect.height,
          distanceFromTop: rect.top + window.scrollY,
        };

        this.elements[key] = elementData;
        root.style.setProperty(`--${key}-height`, `${Math.ceil(rect.height)}px`);
      }
    }

    if (window.scrollY > 0) {
      document.body.classList.add("has-scrolled");
    } else {
      document.body.classList.remove("has-scrolled");
    }
  }
}

document.addEventListener(
  "LMContentLoaded",
  () => {
    DOMElementsInfo.instance = null;
    DOMElementsInfo.getInstance = () => {
      if (!DOMElementsInfo.instance) {
        DOMElementsInfo.instance = new DOMElementsInfo();
      }
      return DOMElementsInfo.instance;
    };
    DOMElementsInfo.getInstance().init();
  },
  { once: true }
);
